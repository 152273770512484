<template>
  <div id="app">
    
    <router-view />
    <!-- <Footer/> -->
  </div>
</template>

<script>



// import Footer from '@/components/Footer.vue'
export default {
    
    components: {
      // Header,
      // Footer
    },
    data(){
      return {
        fullWidth:true,
      }
    },
    methods:{
    }
}


</script>

<style>

#app{
  margin: 0;
  padding: 0;
}
</style>
