import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
//   mode:'history',
  routes: [
    {
      path: '/',
      name: '首页',
      component: () =>import ("@/views/Home.vue")
    },
    {
      path: '/case',
      name: '成功案列',
      component: () =>import ("@/views/case")
    },
    {
      path: '/solution',
      name: '成功案列',
      component: () =>import ("@/views/solution")
    },
    {
      path: '/case/details',
      name: '成功案列详情',
      component: () =>import ("@/views/case/details.vue")
    },
    {
      path: '/me',
      name: '公司介绍',
      component: () =>import ("@/views/me.vue")
    },
    {
      path: '/innovative',
      name: '创新产品',
      component: () =>import ("../views/innovative.vue")
    },
    {
      path: '/search',
      name: '防伪查询',
      component: () =>import ("../views/search.vue")
    },
    
  ]
  
})

const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
